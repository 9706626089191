.App {
  text-align: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 20px;
}

/* table */
.ant-table-pagination.ant-pagination {
  margin: 16px 40px !important;
}

.table-header {
  font-family: "DM Sans";
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 0px;
  text-align: left;
  color: rgb(78, 102, 126);
}
